import React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ButtonContainer from "../../../components/UI/button/button";
import Fade from "@mui/material/Fade";
import CurrencyInput from "react-currency-input-field";


const ScreenOne = (props) => {
  const changeHandler = (event) => {
    if (event.target.value === "Purchase") {
      props.changeScreen(props.nextScreenOne);
    } else if (event.target.value === "Refinance") {
      props.changeScreen(props.nextScreenTwo);
    } else if (event.target.value === "Equity Take Out") {
      props.changeScreen(props.nextScreenTwo);
    } else {
      props.changeScreen(props.nextScreen);
    }
    props.selected(event.target.value);
  };

  const prevHandler = (check) => {
    return check ? props.changeScreen(props.previousScreen) : null;
  };

  const[disableButton, setDisableButton] = React.useState(true);

  const inputChangeHandler = (e) => {
    if(e.target.value !== "") {
      setDisableButton(false);
    }
    props.selected(e.target.value);

  }

  const currencyChangeHandler = (e) => {
    if(e.target.value !== "") {
      setDisableButton(false);
    }
    const value = e.target.value;
    const parsedValue = value.replace(/[^\d.]/gi, "");
    const currencyInt = parseInt(parsedValue);
    const currencyValue = currencyInt.toLocaleString();
    props.selected(currencyValue);

  }

  return (
    <>
      {props.input ? (
        <div className="questionContainer inputQuestionContainer">
          <h3>{props.screenTitle}</h3>
          {props.currency ? (
            <CurrencyInput
            prefix="$"
            name="currencyInput"
            id="currencyInput"
            data-number-to-fixed="2"
            data-number-stepfactor="100"
            placeholder=""
            allowDecimals
            decimalsLimit="2"
            disableAbbreviations
            onChange={currencyChangeHandler}
          />
          ) : (
            <input type={props.type}  onChange={inputChangeHandler}/>
          )}
          <div className="btnContainer">
          <button type="button" onClick={() => {
            props.changeScreen(props.previousScreen);
          }}>Previous</button><button disabled={disableButton} className={disableButton ? "disableBtn" : "enableBtn" } type="button" onClick={() => {
            props.changeScreen(props.nextScreen);
          }}>Next</button>
          </div>
        </div>
        
      ) : (
        <Fade in={true} timeout={2000}>
          <div>
            <ToggleButtonGroup
              orientation="vertical"
              exclusive
              className="questionContainer"
              onChange={changeHandler}
            >
              <h3>{props.screenTitle}</h3>
              {props.surveyOptions.map((item, index) => (
                <ToggleButton
                  value={item.option}
                  aria-label={item.option}
                  key={item.option}
                >
                  {item.option}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
            {props.setPreviousScreen ? (
              <ButtonContainer
                text="Previous Question"
                clickHandler={prevHandler}
              />
            ) : (
              ""
            )}
          </div>
        </Fade>
      )}
    </>
  );
};

export default ScreenOne;
