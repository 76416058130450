import React from "react";
import "./button.css";
import { Button } from "@mui/material";
import Fade from "@mui/material/Fade";
import CircularProgress from '@mui/material/CircularProgress';

const ButtonContainer = (props) => {

  return (
    <Fade in={true} timeout={2000}>
      <Button
      type={props.type}
        onClick={() => {
          try {
            props.clickHandler(true);
          } catch {}

          try {
            props.sendEmail();
            // props.changeScreen("End");
          } catch {}
        }}
        className="mortgageButton"
        disabled = {props.disable ? true : false}
      >
        {props.spinnerCheck ? <CircularProgress /> : `${props.text}`}
      </Button>
    </Fade>
  );
};

export default ButtonContainer;
